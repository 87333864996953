import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import { Logout } from "../../../store/actions/AuthActions";

const UpdateUserDetails = () => {
  const accessToken = JSON.parse(localStorage.getItem("userDetails"));
  let Navigate = useNavigate();
  // Prefilled
  let { id, name, email, mobileNumber } = useParams();
  // console.log(accessToken);

  const [Name, setName] = useState(name);
  const [Email, setEmail] = useState(email);
  const [MobileNumber, setMobileNumber] = useState(mobileNumber);

  //   Error handling
  const [Error, setError] = useState();
  const [Loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  function onLogout() {
    dispatch(Logout(Navigate));
    // window.location.reload();
  }

  const handleUpdate = () => {
    if (Name === "") {
      setError("Name is required");
    } else if (Email === "") {
      setError("Email is required");
    } else if (!/\S+@\S+\.\S+/.test(Email)) {
      setError("Email is not valid");
    } else if (MobileNumber === "") {
      setError("Mobile is required");
    } else if (MobileNumber.length > 11 || MobileNumber.length < 8) {
      setError("Mobile number must be 8 to 11 digits only.");
    }  else {
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

      const raw = JSON.stringify({
        fullname: Name,
        email: Email,
        mobileNumber: MobileNumber,
      });

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + `users/update/${id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result.message === "Successfully Updated") {
            setLoading(false);
            swal("Done!", "Profile updated successfully", "success").then(() =>
              Navigate("/app-profile")
            );
          }else if (result.message === "unauthorized" || result.message === "token expire") {
            swal({
              title: "Session Expired!",
              text: `your session has expired! Kindly login again!`,
              icon: "warning",
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                onLogout();
              } else {
                onLogout();
              }
            });
          } else {
            swal("Failed!", result.message, "error");
            setLoading(false);
          }
        })
        .catch((error) => console.error(error));
    }
  };
  return (
    <div className="">
      <div className="flex  justify-start md:justify-center animatedView ">
        <div className="w-full md:w-1/2">
          <div className="bg-white p-3 rounded-[20px]">
            <div className="">
              <h1 className="text-[#fff] bg-[#0aa1dd] rounded-md p-3 m-2 text-xl">
                Update Profile
              </h1>
            </div>
            <div className="flex items-center">
              <div className="mx-5 w-full flex flex-col gap-3 animatedView my-3">
                <div className="relative">
                  <label htmlFor="document" className="font-semibold">
                    Name:
                  </label>
                  <input
                    type="text"
                    className={` border rounded-lg p-2 w-full`}
                    placeholder="enter customer's name"
                    value={Name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="relative mt-2">
                  <label htmlFor="document" className="font-semibold">
                    Email:
                  </label>
                  <input
                    type="email"
                    className={` border rounded-lg p-2 w-full`}
                    placeholder="enter customer's email"
                    value={Email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="relative mt-2">
                  <label htmlFor="document" className="font-semibold">
                    Mobile Number:
                  </label>
                  <input
                    type="number"
                    className={` border rounded-lg p-2 w-full`}
                    placeholder="enter customer's phone number"
                    value={MobileNumber}
                    onChange={(e) => setMobileNumber(e.target.value)}
                  />
                </div>
                {Error && (
                  <div className="text-xs m-1 text-red-500 animatedView">
                    {Error}
                  </div>
                )}
                {/* Button */}
                <div className=" flex justify-end mt-2 mb-2">
                  <div className="flex justify-between gap-4">
                    <button
                      className="border border-[#0aa1dd] text-[#0aa1dd] rounded p-2 px-4"
                      onClick={() => Navigate(-1)}
                    >
                      Cancel
                    </button>
                    <button
                      className="p-2.5 bg-[#0aa1dd] rounded-md text-white px-3 flex items-center gap-3"
                      onClick={handleUpdate}
                    >
                      {Loading ? (
                        <Spinner animation="border" variant="light"></Spinner>
                      ) : (
                        <>
                          Update Customer
                          <span>
                            <i className="bi bi-check2-all"></i>
                          </span>
                        </>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateUserDetails;
