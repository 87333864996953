// import { Empty } from "antd";
// import React, { useContext } from "react";
// import { LocalStorageContext } from "../../../../context/WalletProvider";
// import moment from "moment";

// const Wallet = () => {
//   const { WalletHistories } = useContext(LocalStorageContext);

//   return (
//     <div className="bg-white rounded-xl p-5">
//       <h3>History of previous wallet balance</h3>
//       <div className="flex flex-col gap-3">
//         {WalletHistories?.length > 0 ? (
//           WalletHistories?.map((el, i) => {
//             const resetDate = moment(el.resetAt);
//             const today = moment().startOf("day");
//             const yesterday = moment().subtract(1, "days").startOf("day");

//             let formattedDate;
//             if (resetDate.isSame(today, "day")) {
//               formattedDate = "Today";
//             } else if (resetDate.isSame(yesterday, "day")) {
//               formattedDate = "Yesterday";
//             } else {
//               formattedDate = resetDate.format("DD-MM-YYYY");
//             }

//             return (
//               <div key={i} className="my-5">
//                 <div className="flex justify-between items-center">
//                   <h4 className="text-[#0aa1dd] font-semibold">
//                     {parseInt(el.amount)} KWD
//                   </h4>
//                   <p className="text-black font-semibold">
//                     <span className="text-gray-600 font-normal">
//                       {formattedDate === "Today"
//                         ? false
//                         : formattedDate === "Yesterday"
//                         ? false
//                         : "Reset At :"}
//                     </span>{" "}
//                     {formattedDate}
//                   </p>
//                 </div>
//                 <hr className="my-2" />
//               </div>
//             );
//           })
//         ) : (
//           <div className="my-10">
//             <Empty />
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default Wallet;

import { Empty } from "antd";
import React, { useContext } from "react";
import { LocalStorageContext } from "../../../../context/WalletProvider";
import moment from "moment";

const Wallet = () => {
  let name = JSON.parse(localStorage.getItem("userDetails"));
  const { WalletHistories } = useContext(LocalStorageContext);

  return (
    <div className="bg-white rounded-xl p-5">
      <h3>History of previous wallet balance</h3>
      <div className="flex flex-col gap-1">
        {WalletHistories?.length > 0 ? (
          WalletHistories.slice()
            .reverse()
            .map((el, i) => {
              // Use slice() to avoid mutating the original array
              const resetDate = moment(el.resetAt);
              const today = moment().startOf("day");
              const yesterday = moment().subtract(1, "days").startOf("day");

              let formattedDate;
              if (resetDate.isSame(today, "day")) {
                formattedDate = "Today";
              } else if (resetDate.isSame(yesterday, "day")) {
                formattedDate = "Yesterday";
              } else {
                formattedDate = resetDate.format("DD-MM-YYYY");
              }
              const formattedTime = resetDate.format("HH:mm:ss");
              return (
                <div key={i} className="my-3">
                  <div className="flex justify-between items-center">
                    <h4 className="flex items-center gap-4">
                      {name?.user?.fullname}
                      <span className="text-[#0aa1dd] font-semibold">
                        {parseInt(el.amount)} KWD
                      </span>
                    </h4>
                    <p className="text-black font-semibold">
                      <span className="text-gray-600 font-normal">
                        {formattedDate === "Today" ||
                        formattedDate === "Yesterday"
                          ? ""
                          : "Reset At :"}
                      </span>{" "}
                      {formattedDate} at {formattedTime}
                    </p>
                  </div>
                  <hr className="my-1" />
                </div>
              );
            })
        ) : (
          <div className="my-10">
            <Empty />
          </div>
        )}
      </div>
    </div>
  );
};

export default Wallet;
