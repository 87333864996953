import {
  DatePicker,
  Select,
  Space,
  Divider,
  ConfigProvider,
  TimePicker,
  Image,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import moment from "moment";
import dayjs from "dayjs";
import { RxCross1 } from "react-icons/rx";
import { FaCloudUploadAlt, FaRegFilePdf, FaRegFileWord } from "react-icons/fa";
import jsPDF from "jspdf";
import { PDFDocument } from "pdf-lib";
import { HiOutlinePlusSmall } from "react-icons/hi2";
import { useDispatch } from "react-redux";
import { Logout } from "../../../../store/actions/AuthActions";

const CreateInvoice = () => {
  let navigate = useNavigate();
  let accessToken = JSON.parse(localStorage.getItem("userDetails"));
  let { id } = useParams();

  // ID
  const [ID, setID] = useState("");
  // ID Main
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [MobileNo, setMobileNo] = useState("");
  const [SMSVia, setSMSVia] = useState("");
  const [InvoiceValue, setInvoiceValue] = useState("");
  const [InvoiceRef, setInvoiceRef] = useState("");
  const [CustomerRef, setCustomerRef] = useState("");
  const [Comment, setComment] = useState("");
  const [TermsCondition, setTermsCondition] = useState("");
  const [ExpireDate, setExpireDate] = useState("");
  const [Documents, setDocuments] = useState([]);
  const [InvoiceStatus, setInvoiceStatus] = useState("");
  // Loaders
  const [SearchLoader, setSearchLoader] = useState(false);
  const [Loader, setLoader] = useState(false);

  // Error handlers
  const [NotFound, setNotFound] = useState(false);
  const [InputError, setInputError] = useState("");

  const [docType, setDocType] = useState();
  const getInvoiceDetails = async () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    await fetch(
      process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + `invoices/${id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.invoice) {
          setName(result?.invoice?.Customer?.name);
          setDocuments(result?.invoice?.InvoiceFiles[0]?.url);
          setDocType(
            result?.invoice?.InvoiceFiles[0]?.url
              ?.split(".")
              .pop()
              .toLowerCase()
          );
          setSMSVia(
            result?.invoice?.sentVia === "" ? "Email" : result?.invoice?.sentVia
          );
          setMobileNo(result?.invoice?.Customer?.mobileNumber);
          setExpireDate(dayjs(result?.invoice?.expiryDate));
          // console.log(result?.invoice?.expiryDate);
          setInvoiceValue(result?.invoice?.amount);
          setInvoiceRef(result?.invoice?.invoiceRef);
          setComment(result?.invoice?.comments);
          setTermsCondition(result?.invoice?.termsAndConditions);
          setCustomerRef(result?.invoice?.customerRef);
          setInvoiceItems(
            result?.invoice?.InvoiceItems.map((item) => ({
              id: item.id,
              details: item.details,
              amount: item.amount,
              quantity: item.quantity,
            }))
          );
          setInvoiceStatus(result?.invoice?.status);
        } else if (
          result.message === "unauthorized" ||
          result.message === "token expire"
        ) {
          swal({
            title: "Session Expired!",
            text: `your session has expired! Kindly login again!`,
            icon: "warning",
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              onLogout();
            } else {
              onLogout();
            }
          });
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    const getCustomerData = () => {
      setNotFound(false);
      if (MobileNo.length >= 8) {
        setSearchLoader(true);
        const myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

        const requestOptions = {
          method: "GET",
          headers: myHeaders,
          redirect: "follow",
        };

        fetch(
          `${process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL}customers?q=${MobileNo}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            // console.log("API Result:", result);
            if (result.customers.length > 0) {
              const customer = result.customers[0];
              setID(customer.id);
              setName(customer.name);
              setEmail(customer.email);
              setMobileNo(customer.mobileNumber);
              setSearchLoader(false);
            } else {
              setSearchLoader(false);
              setNotFound(true);
              setID("");
              setCusName("");
              setCusEmail("");
              setCusMobile("");
            }
          })
          .catch((error) => {
            console.log("Fetch Error:", error);
            setSearchLoader(false);
          });
      }
    };
    getCustomerData();
  }, [MobileNo, accessToken.accessToken]);

  const handleSelectChange = (value) => {
    // console.log("Selected Value:", value); // Log the selected value for debugging
    setMobileNo(value);
  };

  const [DateChnage, setDateChnage] = useState(0);
  const handleExpireChange = (date, dateString) => {
    setExpireDate(date);
    setDateChnage(1);
  };

  const [invoiceItems, setInvoiceItems] = useState([
    { details: "", amount: 0, quantity: 0 },
  ]);

  const handleAddItem = () => {
    setInvoiceItems([...invoiceItems, { details: "", amount: 0, quantity: 0 }]);
  };

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const updatedItems = invoiceItems.map((item, i) => {
      if (i === index) {
        let newValue = value;

        if (name === "amount") {
          // Regular expression to allow numbers with up to 3 decimal places
          if (newValue === "" || /^\d*\.?\d{0,3}$/.test(newValue)) {
            newValue = value; // Allow value to be updated if it matches the pattern
          } else {
            newValue = item.amount; // Keep the previous value if not valid
          }
        }

        // Allow clearing the input for amount or quantity
        if (name === "amount" || name === "quantity") {
          newValue = value === "" ? "" : newValue;
        }

        return { ...item, [name]: newValue };
      }
      return item;
    });

    setInvoiceItems(updatedItems);
  };

  const subtotal = invoiceItems.map((item) => item.amount * item.quantity);

  // Calculate total subtotal
  const totalSubtotal = subtotal.reduce((acc, curr) => acc + curr, 0);

  const handleDeleteItem = (index) => {
    const updatedItems = invoiceItems.filter((item, i) => i !== index);
    setInvoiceItems(updatedItems);
    console.log(updatedItems);
  };

  const [NewFiles, setNewFiles] = useState("");
  const [NewFilesPreview, setNewFilesPreview] = useState("");

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setNewFiles(file); // Get the first file only
    if (!file) return; // Exit if no file is selected

    const fileType = file.type.split("/")[0];

    let newPreview = null;

    if (fileType === "image") {
      newPreview = { id: 0, type: "image", url: URL.createObjectURL(file) };
    } else if (fileType === "application" && file.type.includes("pdf")) {
      newPreview = { id: 0, type: "pdf", name: file.name };
    } else if (
      fileType === "application" &&
      file.type.includes(
        "vnd.openxmlformats-officedocument.wordprocessingml.document"
      )
    ) {
      newPreview = { id: 0, type: "word", name: file.name };
    }

    if (newPreview) {
      setNewFilesPreview([newPreview]); // Replace existing previews with the new one
    }
  };

  const dispatch = useDispatch();
  function onLogout() {
    dispatch(Logout(navigate));
    // window.location.reload();
  }

  const handleUpdateInvoice = () => {
    let invoiceItemsWithId = [];
    let newItems = [];
    console.log(NewFiles);

    invoiceItemsWithId = invoiceItems.filter((item) => item.id); // Items with an existing ID
    newItems = invoiceItems.filter((item) => !item.id); // Items without an ID

    const filteredNewInvoiceItems = newItems.filter(
      (item) => item.amount !== 0 || item.quantity !== 0
    );
    const filteredExistingInvoiceItems = invoiceItemsWithId.filter(
      (item) => parseInt(item.amount) !== 0 || parseInt(item.quantity) !== 0
    );

    // console.log(filteredExistingInvoiceItems);

    // Valid
    const invalidItemsExist = newItems.some(
      (item) =>
        item.amount === 0 ||
        item.amount === "0.0" ||
        item.amount === "0.00" ||
        item.amount === "0.000" ||
        item.quantity === 0 ||
        item.quantity === "0"
    );

    const invalidItemsNew = invoiceItemsWithId.some(
      (item) =>
        item.amount === 0 ||
        item.amount === "0.0" ||
        item.amount === "0.00" ||
        item.amount === "0.000" ||
        item.quantity === 0 ||
        item.quantity === "0"
    );

    setInputError("");
    if (
      Name === "" ||
      MobileNo === "" ||
      ExpireTime === undefined ||
      ExpireDate === ""
    ) {
      swal("Failed!", "Please fill all the required fields", "error");
    } else if (
      totalSubtotal === "0" ||
      totalSubtotal === "0.0" ||
      totalSubtotal === "0.00" ||
      totalSubtotal === "0.000"
    ) {
      swal("Error!", `Invoice amount cannot be ${totalSubtotal}`, "error");
    } else {
      if (invalidItemsExist || invalidItemsNew) {
        // Show Swal error if any invalid item is found
        swal("Error", "Item quantity or amount should not be 0", "error");
      } else {
        setLoader(true);
        let myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

        // Create a FormData object
        let formData = new FormData();

        formData.append("amount", totalSubtotal);
        formData.append("invoiceRef", InvoiceRef);
        formData.append("expiryDate", dayjs(ExpireDate).format("YYYY-MM-DD"));
        formData.append("comments", Comment);
        formData.append("customerId", ID);
        formData.append("sentVia", SMSVia);
        formData.append("customerRef", CustomerRef);
        formData.append("termsAndConditions", TermsCondition);

        // Append the file if it exists
        if (NewFiles !== "") {
          formData.append("invoiceFiles", NewFiles); // Assuming NewFiles is a single file array
          console.log("dasdasd", NewFiles);
        } else {
          formData.append("invoiceFiles", JSON.stringify(Documents)); // Handle this differently if needed
        }

        // Convert the invoice items to JSON strings and append them
        formData.append(
          "invoiceItems",
          JSON.stringify(filteredExistingInvoiceItems)
        );
        formData.append("newItems", JSON.stringify(filteredNewInvoiceItems));

        let requestOptions = {
          method: "PUT",
          headers: myHeaders,
          body: formData, // Use FormData here
          redirect: "follow",
        };

        fetch(
          process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
            `invoices/update/${id}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            console.log(result);
            if (result.message === "Successfully Updated") {
              setLoader(false);
              swal("Updated!", result.message, "success");
              navigate("/invoice-list");
            } else if (
              result.message === "unauthorized" ||
              result.message === "token expire"
            ) {
              swal({
                title: "Session Expired!",
                text: `your session has expired! Kindly login again!`,
                icon: "warning",
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  onLogout();
                } else {
                  onLogout();
                }
              });
            } else {
              swal("Failed!", result.message, "error");
              setLoader(false);
            }
          })
          .catch((error) => {
            console.log("error", error);
            setLoader(false);
          });
      }
    }
  };

  const [CustomerData, setCustomerData] = useState([]);
  const getCustomerData = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(
      process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + `customers`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // console.log(result);
        if (result.customers) {
          setCustomerData(result.customers);
        } else if (
          result.message === "unauthorized" ||
          result.message === "token expire"
        ) {
          swal({
            title: "Session Expired!",
            text: `your session has expired! Kindly login again!`,
            icon: "warning",
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              onLogout();
            } else {
              onLogout();
            }
          });
        }
      })
      .catch((error) => console.log("error", error));
  };
  useEffect(() => {
    getCustomerData();
    getInvoiceDetails();
  }, [accessToken.accessToken]);

  const Users = () => {
    return CustomerData.map((el) => ({
      value: el.mobileNumber,
      label: (
        <div className="flex justify-between items-center">
          <div
            className="flex gap-2 items-center"
            onClick={() => {
              setID(el.customers?.id);
              setName(el.customers?.name);
              setEmail(el.customers?.email);
              setMobileNo(el.customers?.mobileNumber);
            }}
          >
            <i className="bi bi-person-bounding-box text-[#0aa1dd]"></i>
            <p className="font-semibold">{el.name}</p>
          </div>
          <div>{el.mobileNumber}</div>
        </div>
      ),
    }));
  };

  useEffect(() => {
    setCustomerRef(Name && Name?.slice(0, 3) + "/" + MobileNo?.slice(0, 3));
  }, [MobileNo, Name]);

  const filterOption = (input, option) => {
    const label = (option?.value ?? "").toLowerCase();
    const inputValue = input.toLowerCase();
    return label.includes(inputValue);
  };

  const [cusName, setCusName] = useState("");
  const [cusEmail, setCusEmail] = useState("");
  const [cusMobile, setCusMobile] = useState("");
  const [Loading, setLoading] = useState(false);
  const [Error, setError] = useState(false);

  const CreateCustomer = () => {
    if (cusName === "") {
      setError("name is required");
    } else if (cusMobile === "") {
      setError("mobile is required");
    } else if (cusMobile.length > 11 || cusMobile.length < 8) {
      setError("mobile number must be 8 to 11 digits only.");
    } else {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

      var raw = JSON.stringify({
        name: cusName,
        email: cusEmail,
        mobileNumber: cusMobile,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + "customers/new",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result.message === "Successfully Created") {
            getCustomerData();
            setLoading(false);
            setCusName("");
            setCusEmail("");
            setCusMobile("");
          } else {
            swal("Denied!", `${result.message}`, "error");
            setLoading(false);
          }
        })
        .catch((error) => console.log("error", error));
    }
  };

  const disabledDate = (current) => {
    // Disable dates before today
    return current && current < moment().endOf("day");
  };

  const defaultTime = new Date();
  defaultTime.setHours(0);
  defaultTime.setMinutes(0);
  const [ExpireTime, setExpireTime] = useState(defaultTime);

  const handleTimeChange = (time, timeString, DateChnage) => {
    if (DateChnage === 0) {
      setExpireTime(timeString);
    } else setExpireTime(dayjs().hour(12).minute(0).second(0).millisecond(0));
  };

  // Upload Logic

  const fileInputRef = useRef(null);
  const handleUpdateClick = () => {
    // Trigger click event on file input
    fileInputRef.current.click();
  };

  // Documents to PDF
  const extractFileName = (url) => {
    const parts = url.split("/");
    return parts[parts.length - 1];
  };

  const dataURItoBlob = (dataURI, type) => {
    const byteString = atob(dataURI.split(",")[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer], { type: type });
  };

  const handlePdf = (file) => {
    const fileName = extractFileName(file);
    const fileExtension = fileName.split(".").pop().toLowerCase();

    if (
      fileExtension === "jpeg" ||
      fileExtension === "jpg" ||
      fileExtension === "png"
    ) {
      // Handle image files with jsPDF
      const img = new Image();
      img.src = file;
      img.onload = () => {
        const pdf = new jsPDF();
        const marginLeft = 10; // Left margin
        const marginRight = 10; // Right margin
        const marginTop = 10; // Top margin
        const marginBottom = 10; // Bottom margin

        // Calculate available width and height after applying margins
        const availableWidth =
          pdf.internal.pageSize.getWidth() - (marginLeft + marginRight);
        const availableHeight =
          pdf.internal.pageSize.getHeight() - (marginTop + marginBottom);

        // Calculate image dimensions to fit within available space
        const imgWidth = Math.min(
          availableWidth,
          availableHeight * (img.naturalWidth / img.naturalHeight)
        );
        const imgHeight = imgWidth * (img.naturalHeight / img.naturalWidth);

        // Calculate positioning to center image within available space
        const xPos = (pdf.internal.pageSize.getWidth() - imgWidth) / 2;
        const yPos = (pdf.internal.pageSize.getHeight() - imgHeight) / 2;

        // Add image with margins
        pdf.addImage(img, "JPEG", xPos, yPos, imgWidth, imgHeight);
        const pdfDataUri = pdf.output("datauristring");
        const blob = dataURItoBlob(pdfDataUri, "application/pdf");
        const blobUrl = URL.createObjectURL(blob);
        const newTab = window.open(blobUrl);
        newTab.onload = () => {
          newTab.document.title = fileName;
        };
      };
    } else if (fileExtension === "docx") {
      // Open DOCX file in new tab
      const newTab = window.open(file, "_blank");
      if (newTab) {
        newTab.opener = null; // Ensure the new tab doesn't retain reference to the opener
        newTab.focus();
      } else {
        console.error("Failed to open new tab.");
      }
    } else if (fileExtension === "pdf") {
      // Handle PDF files
      fetch(file)
        .then((response) => response.arrayBuffer())
        .then(async (arrayBuffer) => {
          const pdfDoc = await PDFDocument.load(arrayBuffer);
          const pdfBytes = await pdfDoc.save();
          const blob = new Blob([pdfBytes], { type: "application/pdf" });
          const blobUrl = URL.createObjectURL(blob);
          const newTab = window.open(blobUrl);
          newTab.onload = () => {
            newTab.document.title = fileName;
          };
        })
        .catch((error) => console.error("Error loading PDF:", error));
    } else {
      console.error("Unsupported file type:", fileExtension);
    }
  };

  return (
    <div className="flex flex-col gap-6 animatedView">
      {InputError && (
        <div className="text-sm text-red-500 animatedView">{InputError}</div>
      )}
      {InvoiceStatus === "Expired" && (
        <div className="bg-red-100 border-[1px] border-dotted font-semibold border-red-500 rounded-xl p-3">
          <p className="text-red-500 text-lg">Invoice Expired!</p>
        </div>
      )}
      {/* First Row */}
      <div className="flex flex-col md:flex-col lg:flex-row gap-4">
        {/* Mobile */}
        <div className="w-full lg:w-1/2">
          <label
            htmlFor="name"
            className="text-[#545454] text-[14px] font-semibold"
          >
            Mobile Number <span className="text-red-500">*</span>
          </label>
          <div className="flex items-center relative">
            <div className="bg-white border-r rounded-l-lg">
              <select
                id="countries"
                className="bg-white text-gray-900 text-sm rounded-lg block py-[11.9px] px-[15px]"
              >
                <option className="p-1" defaultValue>
                  +965
                </option>
                <option className="p-1" value="US">
                  +989
                </option>
                <option className="p-1" value="CA">
                  +91
                </option>
              </select>
            </div>
            <ConfigProvider
              theme={{
                token: {
                  controlHeight: 36,
                  borderRadius: "0px 6px 6px 0px",
                  colorBorder: "rgba(255,255,255,0)",
                  colorPrimaryHover: "#0aa1dd",
                  lineWidth: 0,
                  fontSize: 12,
                },
              }}
            >
              <Select
                showSearch
                size="large"
                placeholder="Select a person"
                className="w-full"
                optionFilterProp="children"
                filterOption={filterOption}
                options={Users()}
                onChange={(value) => setMobileNo(value)}
                value={MobileNo}
                dropdownRender={(menu) => (
                  <>
                    {menu}
                    <Divider
                      style={{
                        margin: "8px 0",
                      }}
                    />
                    <Space
                      style={{
                        padding: "0 8px 4px",
                      }}
                    >
                      <input
                        type="text"
                        name="name"
                        id=""
                        className={`${
                          Error && Error.length > 0
                            ? "border border-red-500 placeholder:text-red-500"
                            : "border border-gray-200"
                        } border rounded-lg p-2 w-full`}
                        value={cusName}
                        onChange={(e) => setCusName(e.target.value)}
                        placeholder="Enter name"
                      />
                      <input
                        type="text"
                        name="name"
                        id=""
                        className={`${
                          Error && Error.length > 0
                            ? "border border-red-500 placeholder:text-red-500"
                            : "border border-gray-200"
                        } border rounded-lg p-2 w-full`}
                        value={cusMobile}
                        onChange={(e) => setCusMobile(e.target.value)}
                        placeholder="Enter mobile"
                      />
                      <input
                        type="text"
                        name="name"
                        id=""
                        className={`${
                          Error && Error.length > 0
                            ? "border border-red-500 placeholder:text-red-500"
                            : "border border-gray-200"
                        } border rounded-lg p-2 w-full`}
                        value={cusEmail}
                        onChange={(e) => setCusEmail(e.target.value)}
                        placeholder="Enter email"
                      />
                      <button
                        className="flex items-center gap-2"
                        onClick={CreateCustomer}
                      >
                        {Loading ? (
                          <Spinner animation="border" size="sm" />
                        ) : (
                          <>
                            <PlusOutlined />
                            <span>Add</span>
                          </>
                        )}
                      </button>
                    </Space>
                  </>
                )}
              />
            </ConfigProvider>
            {SearchLoader && (
              <div className="absolute right-4 top-4">
                <Spinner animation="border" variant="primary" size="sm" />
              </div>
            )}
          </div>
          <p className="text-xs tracking-wide py-1 text-[#545454]">
            {NotFound === true ? (
              <span className="text-red-500">Not Found</span>
            ) : (
              "please enter full number to get customer data."
            )}
          </p>
        </div>
        {/* Via */}
        <div className="w-full lg:w-1/4">
          <div className="flex flex-col">
            <label
              htmlFor="name"
              className="text-[#545454] text-[14px] font-semibold"
            >
              Send Invoice via
            </label>
            <select
              id="countries"
              name="smsVia"
              value={SMSVia}
              onChange={(e) => setSMSVia(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full py-[11px] px-[15px]"
            >
              <option className="p-1" value="SMS">
                SMS
              </option>
              <option className="p-1" value="Email">
                Email
              </option>
            </select>
          </div>
        </div>
        {/* Expire */}
        <div className="w-full lg:w-1/4 flex gap-2">
          <div className="flex flex-col w-full">
            <label
              htmlFor="name"
              className="text-[#545454] text-[14px] font-semibold"
            >
              Invoice Expire Date <span className="text-red-500">*</span>
            </label>
            <div className="flex items-center gap-2">
              <DatePicker
                onChange={handleExpireChange}
                className="w-full px-3 placeholder:text-black placeholder:font-semibold border-none"
                style={{ padding: 9 }}
                placeholder="dd/mm/yyyy 11:12:00"
                // disabledDate={disabledDate}
                value={ExpireDate}
                format="DD/MM/YYYY"
                allowClear={false}
              />
              <TimePicker
                use12Hours
                className="w-full px-3 placeholder:text-black placeholder:font-semibold border-none"
                style={{ padding: 9 }}
                format="h:mm A"
                value={dayjs().hour(12).minute(0).second(0).millisecond(0)}
                onChange={() => handleTimeChange(DateChnage)}
                allowClear={false}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Second Row */}
      <div className="flex flex-col md:flex-col lg:flex-row gap-4">
        {/* Name */}
        <div className="w-full lg:w-1/2 flex flex-col">
          <label
            htmlFor="name"
            className="text-[#545454] text-[14px] font-semibold"
          >
            Customer Name <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="name"
            id=""
            className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none w-full disabled:bg-white disabled:cursor-not-allowed"
            value={Name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your customer name"
            disabled
          />
        </div>
        {/* Invoice Value */}
        <div className="w-full lg:w-1/4 flex flex-col">
          {/* Second input taking 25% space */}
          <label
            htmlFor="name"
            className="text-[#545454] text-[14px] font-semibold"
          >
            Invoice Amount <span className="text-red-500">*</span>
          </label>
          <input
            type="text"
            name="invoiceValue"
            id=""
            className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none w-full disabled:bg-white disabled:cursor-not-allowed"
            value={totalSubtotal}
            onChange={(e) => setInvoiceValue(e.target.value)}
            placeholder="Kuwait [KD]"
            disabled
          />
        </div>
        {/* Invoice Ref */}
        <div className="w-full lg:w-1/4 flex flex-col">
          {/* Second input taking 25% space */}
          <label
            htmlFor="name"
            className="text-[#545454] text-[14px] font-semibold"
          >
            Invoice Ref
          </label>
          <input
            type="text"
            name="invoiceRef"
            id=""
            className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none w-full"
            value={InvoiceRef}
            onChange={(e) => setInvoiceRef(e.target.value)}
          />
        </div>
      </div>
      {/* Third Row */}
      <div className="flex flex-col md:flex-col lg:flex-row gap-5 relative">
        {/* Email */}
        <div className="w-full lg:w-1/2 flex flex-col">
          <label
            htmlFor="name"
            className="text-[#545454] text-[14px] font-semibold"
          >
            Customer Email
          </label>
          <input
            type="text"
            name="email"
            id=""
            className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none w-full disabled:bg-white disabled:cursor-not-allowed"
            value={Email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="jhon@example.com"
            disabled
          />
        </div>
        {/* Customer Ref */}
        <div className="w-full lg:w-1/4 flex flex-col">
          <label
            htmlFor="name"
            className="text-[#545454] text-[14px] font-semibold"
          >
            Customer Ref
          </label>
          <input
            type="text"
            name="customerRef"
            id=""
            className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none w-full disabled:bg-white disabled:cursor-not-allowed"
            value={CustomerRef}
            onChange={(e) => setCustomerRef(e.target.value)}
            placeholder=""
            disabled
          />
        </div>
        {/* Comment */}
        <div className="w-full lg:w-1/4 flex flex-col">
          <label
            htmlFor="name"
            className="text-[#545454] text-[14px] font-semibold"
          >
            Comment
          </label>
          <textarea
            name="comment"
            id=""
            cols="30"
            rows="10"
            className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none w-full"
            value={Comment}
            onChange={(e) => setComment(e.target.value)}
          ></textarea>
        </div>
        {/* Term and Condition */}
        <div className="w-[74%] absolute bottom-0 hidden md:hidden lg:block">
          <div>
            <label
              htmlFor="name"
              className="text-[#545454] text-[14px] font-semibold"
            >
              Terms & Conditions
            </label>
            <textarea
              name="termsConditions"
              id=""
              cols="30"
              rows="5"
              className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none w-full"
              value={TermsCondition}
              onChange={(e) => setTermsCondition(e.target.value)}
            ></textarea>
          </div>
        </div>
      </div>
      {/* fourth for the mobile */}

      <div className="w-full block md:block lg:hidden">
        <label
          htmlFor="name"
          className="text-[#545454] text-[14px] font-semibold"
        >
          Terms & Conditions
        </label>
        <textarea
          name="termsConditions"
          id=""
          cols="30"
          rows="4"
          className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none w-full"
          value={TermsCondition}
          onChange={(e) => setTermsCondition(e.target.value)}
        ></textarea>
      </div>
      {/* Documents */}
      <h1 className="text-[#545454] font-semibold text-base ">
        Uploaded Documents
      </h1>
      <div className="flex flex-wrap gap-3">
        {/* Existing */}
        {NewFiles === "" ? (
          <div className="animatedView">
            <div className="flex flex-wrap gap-3">
              <div className="relative">
                {docType === "jpg" ||
                docType === "png" ||
                docType === "jpeg" ? (
                  <Image
                    src={Documents}
                    style={{
                      width: 150,
                      height: 150,
                      borderRadius: 10,
                      objectFit: "cover",
                    }}
                  />
                ) : docType === "pdf" ? (
                  <div className="bg-white w-[150px] h-[150px] rounded-[10px] flex flex-col justify-between py-2.5">
                    <div className="flex flex-col gap-3">
                      <div className="flex justify-center">
                        <FaRegFilePdf className="text-5xl text-red-600" />
                      </div>
                      <h1 className="text-sm text-gray-500 text-center">
                        {Documents?.length > 13
                          ? Documents?.slice(0, 13) + "..."
                          : Documents}
                      </h1>
                    </div>
                    <div className="flex justify-center">
                      <button
                        className="bg-red-600 py-2 px-5 text-white text-xs rounded"
                        onClick={() => handlePdf(Documents)}
                      >
                        View PDF
                      </button>
                    </div>
                  </div>
                ) : docType === "doc" || docType === "docx" ? (
                  <div className="bg-white w-[150px] h-[150px] rounded-[10px] flex flex-col justify-between py-2.5">
                    <div className="flex flex-col gap-3">
                      <div className="flex justify-center">
                        <FaRegFileWord className="text-5xl text-blue-800" />
                      </div>
                      <h1 className="text-sm text-gray-500 text-center">
                        {Documents?.length > 13
                          ? Documents?.split("/").pop()?.slice(0, 13) + "..."
                          : Documents}
                      </h1>
                    </div>
                    <div className="flex justify-center">
                      <button
                        className="bg-blue-800 py-2 px-5 text-white text-xs rounded"
                        onClick={() => handlePdf(Documents)}
                      >
                        View Word
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-wrap gap-3">
            {NewFilesPreview.map((preview, index) => (
              <div key={index} className="relative">
                {preview?.type === "image" ? (
                  <Image
                    src={preview.url}
                    alt={`preview-${index}`}
                    style={{
                      width: 150,
                      height: 150,
                      borderRadius: 10,
                      objectFit: "cover",
                    }}
                  />
                ) : preview?.type === "pdf" ? (
                  <div className="bg-white w-[150px] h-[150px] rounded-[10px] flex justify-center items-center">
                    <div className="flex flex-col gap-3">
                      <div className="flex justify-center">
                        <FaRegFilePdf className="text-5xl text-red-600" />
                      </div>
                      <h1 className="text-sm text-gray-500 text-center">
                        {preview?.name?.length > 13
                          ? preview?.name?.slice(0, 13) + "..."
                          : preview?.name}
                      </h1>
                    </div>
                  </div>
                ) : preview?.type === "word" ? (
                  <div className="bg-white w-[150px] h-[150px] rounded-[10px] flex justify-center items-center">
                    <div className="flex flex-col gap-3">
                      <di v className="flex justify-center">
                        <FaRegFileWord className="text-5xl text-blue-800" />
                      </di>
                      <h1 className="text-sm text-gray-500 text-center">
                        {preview?.name?.length > 13
                          ? preview?.name?.slice(0, 13) + "..."
                          : preview?.name}
                      </h1>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ))}
          </div>
        )}
        {/* Upload */}
        <div className="">
          <div
            className="bg-gray-100 h-[150px] w-[150px] flex justify-center items-center gap-2 text-black border border-gray-300 text-center rounded-full p-2 text-sm font-semibold cursor-pointer"
            onClick={handleUpdateClick}
          >
            <div className="text-center text-gray-500 text-xs font-normal">
              <div className="flex justify-center">
                <HiOutlinePlusSmall size={60} className="text-gray-500" />
              </div>
              Change file
            </div>
          </div>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: "none" }}
            onChange={handleImageChange}
          />
        </div>
      </div>

      {/* Add Item */}
      <div className="mt-[66px]">
        {/* Button */}
        <button
          className="text-[#545454] font-semibold text-base flex items-center gap-2"
          onClick={handleAddItem}
        >
          Add Item{" "}
          <span>
            <i className="bi bi-plus-circle text-lg"></i>
          </span>
        </button>
        <div className="border-t border-dashed w-full border-[#545454]" />
        {/* Items */}
        {invoiceItems.map((item, index) => (
          <div
            className="flex flex-col md:flex-col lg:flex-row gap-4 mt-3 animatedView items-center"
            key={index}
          >
            <div className="w-full lg:w-1/2 flex flex-col">
              <label className="text-[#545454] text-[14px] font-semibold">
                Details:
              </label>
              <input
                type="text"
                name="details"
                className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none w-full"
                placeholder="Enter details"
                value={item.details}
                onChange={(e) => handleInputChange(index, e)}
              />
            </div>
            <div className="w-full lg:w-1/4 flex flex-col">
              <label className="text-[#545454] text-[14px] font-semibold">
                Amount:
              </label>
              <input
                type="text"
                name="amount"
                className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none w-full"
                value={item.amount}
                onChange={(e) => handleInputChange(index, e)}
              />
            </div>
            <div className="w-full lg:w-1/4 flex flex-col">
              <label className="text-[#545454] text-[14px] font-semibold">
                Quantity:
              </label>
              <input
                type="text"
                name="quantity"
                className="text-[14px] py-[11px] px-[15px] rounded-lg outline-none w-full"
                value={item.quantity}
                onChange={(e) => handleInputChange(index, e)}
              />
            </div>
            <button className="mt-4" onClick={() => handleDeleteItem(index)}>
              <div className="flex justify-center items-center w-11 h-11 bg-red-500 rounded-full">
                <RxCross1 className="text-white" size={20} />
              </div>
            </button>
          </div>
        ))}
      </div>
      {/* button */}
      <div className="flex justify-center mb-5">
        <button
          className="text-white bg-[#0aa1dd] font-semibold text-[20px] py-2 px-4 rounded-lg"
          onClick={handleUpdateInvoice}
        >
          {Loader === true ? (
            <Spinner animation="border" variant="light" />
          ) : (
            "UPDATE INVOICE"
          )}
        </button>
      </div>
    </div>
  );
};

export default CreateInvoice;
