import { lazy, Suspense, useEffect } from "react";

/// Components
import Index from "./jsxClient";
import SubUserIndex from "./jsxSubusers/SubUserIndex";
import {
  connect,
  useDispatch,
  // useDispatch
} from "react-redux";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
// action
// import { checkAutoLogin } from "./services/AuthService";
import { isAuthenticated } from "./store/selectors/AuthSelectors";
import { adminPrevilage } from "./store/selectors/AuthSelectors";
/// Style
//import './vendor/swiper/swiper-bundle.css';
import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";
import { checkAutoLogin } from "./services/AuthService";
import ForgotPassword from "./jsxClient/pages/Auth/Pages/ForgotPassword";
import ResetPassword from "./jsxClient/pages/Auth/Pages/ResetPassword";
import { NotificationContextProvider } from "./context/Notification";
import { TokenContextProvider } from "./context/RefreshToken";
import { PermissionProvider } from "./context/Permission";
// import Success from "./jsx/pages/Auth/Pages/Requirements From/Success";

const Register = lazy(() => import("./jsxClient/pages/Auth/Pages/Register"));
const Login = lazy(() => {
  return new Promise((resolve) => {
    setTimeout(
      () => resolve(import("./jsxClient/pages/Auth/Pages/Login")),
      500
    );
  });
});

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    let location = useLocation();
    let navigate = useNavigate();
    let params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

function App(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    checkAutoLogin(dispatch, navigate);
  }, [checkAutoLogin]);

  let routesBlog = (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/page-register" element={<Register />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/reset-password" element={<ResetPassword />} />
    </Routes>
  );

  if (props.isAuthenticated) {
    return (
      <>
        {props.adminPrevilage === "sub_user" ? (
          <Suspense
            fallback={
              <div id="preloader">
                <div className="sk-three-bounce">
                  <div className="sk-child sk-bounce1"></div>
                  <div className="sk-child sk-bounce2"></div>
                  <div className="sk-child sk-bounce3"></div>
                </div>
              </div>
            }
          >
            <NotificationContextProvider>
            <TokenContextProvider>
              <PermissionProvider>
                <SubUserIndex />
              </PermissionProvider>
            </TokenContextProvider>
            </NotificationContextProvider>
          </Suspense>
        ) : (
          <Suspense
            fallback={
              <div id="preloader">
                <div className="sk-three-bounce">
                  <div className="sk-child sk-bounce1"></div>
                  <div className="sk-child sk-bounce2"></div>
                  <div className="sk-child sk-bounce3"></div>
                </div>
              </div>
            }
          >
            <NotificationContextProvider>
              <TokenContextProvider>
                <PermissionProvider>
                  <Index />
                </PermissionProvider>
              </TokenContextProvider>
            </NotificationContextProvider>
          </Suspense>
        )}
      </>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          {routesBlog}
        </Suspense>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
    adminPrevilage: adminPrevilage(state),
  };
};

export default withRouter(connect(mapStateToProps)(App));
