import React from "react";
import pay from "../../../../../images/svg/pay.svg";

const PaymentDetails = ({ Card, Label, Desc, data }) => {
  return (
    <Card Head="Payment Informaion Details" Icons={pay}>
      <div>
        <Label label="Paid Date" />
        <Desc
          desc={
            data?.Transactions?.length > 0
              ? new Date(data?.Transactions?.[0].end)
                  .toISOString()
                  .split("T")[0]
              : "-"
          }
        />
      </div>
      <div>
        <Label label="Invoice Value" />
        <Desc desc={data?.amount + " " + data?.currency} />
      </div>
      {/* <div>
        <Label label="Vendor Service Charge" />
        <Desc desc="-" />
      </div> */}
      {/* <div>
        <Label label="Customer Service Charge" />
        <Desc desc="-" />
      </div> */}
      {/* <div>
        <Label label="Due Value" />
        <Desc desc="-" />
      </div>
      <div>
        <Label label="Due Deposit" />
        <Desc desc="-" />
      </div>
      <div>
        <Label label="Deposit Date" />
        <Desc desc="-" />
      </div> */}
    </Card>
  );
};

export default PaymentDetails;
