import React, { useState } from "react";
import { Image, Table } from "antd";
import moment from "moment";
import { FaRegFilePdf, FaRegFileWord } from "react-icons/fa";
import jsPDF from "jspdf";
import { PDFDocument } from "pdf-lib";
import { Link } from "react-router-dom";

const Button = ({ children, onClick, active, btnName }) => {
  return (
    <button
      className={`${
        active === btnName
          ? "bg-[#0aa1dd] text-white"
          : "bg-gray-200 text-[#000]"
      } font-semibold p-3 text-sm rounded-md px-5 transition-all  duration-300 ease-in-out`}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

const DetailTabs = ({ data }) => {
  const [Name, setName] = useState("InvoiceItems");
  const [CurrentTab, setCurrentTab] = useState("InvoiceItems");

  const nextTab = (name) => {
    setCurrentTab(name);
  };

  let TabToShow;
  switch (CurrentTab) {
    case "InvoiceItems":
      TabToShow = <InvoiceItems data={data?.InvoiceItems} />;
      break;
    case "InvoiceTransaction":
      TabToShow = <InvoiceTransactions data={data?.Transactions} />;
      break;
    case "InvoiceViews":
      TabToShow = <InvoiceViews data={data?.InvoiceViews} />;
      break;
    case "UploadedFiles":
      TabToShow = <UploadedFiles data={data?.InvoiceFiles[0]} />;
      break;
    case "SMSDetails":
      TabToShow = (
        <SMSDetails
          Data={
            data?.sentVia === "Email" ? data?.InvoiceEmails : data?.InvoiceSMS
          }
          data={data}
        />
      );
    default:
      break;
  }
  return (
    <div>
      <hr className="my-3" />
      {console.log(data)}
      {/* Flex Buttons */}
      <div className="flex flex-wrap flex-row gap-4">
        <Button
          active={Name}
          btnName={"InvoiceItems"}
          onClick={() => {
            setName("InvoiceItems");
            nextTab("InvoiceItems");
          }}
        >
          Invoice Item
        </Button>
        <Button
          active={Name}
          btnName={"InvoiceTransaction"}
          onClick={() => {
            setName("InvoiceTransaction");
            nextTab("InvoiceTransaction");
          }}
        >
          Invoice Transaction
        </Button>
        <Button
          active={Name}
          btnName={"InvoiceViews"}
          onClick={() => {
            setName("InvoiceViews");
            nextTab("InvoiceViews");
          }}
        >
          Invoice Views
        </Button>
        <Button
          active={Name}
          btnName={"UploadedFiles"}
          onClick={() => {
            setName("UploadedFiles");
            nextTab("UploadedFiles");
          }}
        >
          Uploaded Files
        </Button>
        <Button
          active={Name}
          btnName={"SMSDetails"}
          onClick={() => {
            setName("SMSDetails");
            nextTab("SMSDetails");
          }}
        >
          {data?.sentVia} Details
        </Button>
      </div>
      <div className="my-2 mx-1 animatedView min-h-40">{TabToShow}</div>
    </div>
  );
};

export default DetailTabs;

export const InvoiceItems = ({ data }) => {
  const columns = [
    {
      title: <div className="text-xs">Invoice / Product Name</div>,
      dataIndex: "details",
      key: "id",
      render: (text) => (
        <p className="text-xs font-extrabold tracking-wider">{text}</p>
      ),
    },
    {
      title: <div className="text-xs">Amount</div>,
      dataIndex: "amount",
      key: "id",
      render: (text) => <p className="text-xs font-semibold">{text}</p>,
    },
  ];

  return (
    <div className="animatedView mt-4">
      <Table columns={columns} dataSource={data} />
    </div>
  );
};
export const InvoiceTransactions = ({ data }) => {
  const columnsTrans = [
    {
      title: <div className="text-xs">Transaction Start</div>,
      dataIndex: "start",
      key: "start",
      render: (text) => (
        <a className="text-xs font-semibold">
          {moment(text).format("MM-DD-YYYY, hh:mm a")}
        </a>
      ),
    },
    {
      title: <div className="text-xs">Customer Paid</div>,
      dataIndex: "amountPaid",
      key: "orderId",
      render: (text) => <a className="text-xs font-semibold">{text}</a>,
    },
    {
      title: <div className="text-xs">Commission</div>,
      dataIndex: "commission",
      key: "orderId",
      render: (text) => (
        <a className="text-xs font-semibold">{text ? text : "-"}</a>
      ),
    },
    {
      title: <div className="text-xs">Received Amount</div>,
      dataIndex: "amountReceived",
      key: "orderId",
      render: (text) => <a className="text-xs font-semibold">{text}</a>,
    },
    {
      title: <div className="text-xs">Transaction Status</div>,
      dataIndex: "status",
      key: "orderId",
      render: (text) => <a className="text-xs font-semibold">{text}</a>,
    },
    {
      title: <div className="text-xs">Payment Gateway</div>,
      dataIndex: "paymentGateway",
      key: "orderId",
      render: (text) => (
        <a className="text-xs font-semibold">{text ? text : "-"}</a>
      ),
    },
    {
      title: <div className="text-xs">Payment ID</div>,
      dataIndex: "paymentId",
      key: "orderId",
      render: (text) => <a className="text-xs font-semibold">{text}</a>,
    },
    {
      title: <div className="text-xs">Auth</div>,
      dataIndex: "auth",
      key: "orderId",
      render: (text) => <a className="text-xs font-semibold">{text}</a>,
    },
    {
      title: <div className="text-xs">Track ID</div>,
      dataIndex: "trackId",
      key: "orderId",
      render: (text) => <a className="text-xs font-semibold">{text}</a>,
    },
    {
      title: <div className="text-xs">Transaction ID</div>,
      dataIndex: "transactionId",
      key: "orderId",
      render: (text) => <a className="text-xs font-semibold">{text}</a>,
    },
    {
      title: <div className="text-xs">Transaction End</div>,
      dataIndex: "end",
      key: "orderId",
      render: (text) => (
        <a className="text-xs font-semibold">
          {moment(text).format("MM-DD-YYYY, hh:mm a")}
        </a>
      ),
    },
  ];
  return (
    <div className="animatedView mt-4 overflow-x-scroll">
      <Table columns={columnsTrans} dataSource={data} />
    </div>
  );
};
export const InvoiceViews = ({ data }) => {
  return (
    <div className="animatedView flex flex-col gap-3 my-5">
      {data?.length > 0 ? (
        data?.map((el, i) => {
          return (
            <div
              className="bg-white p-3 rounded-2xl shadow-xl border-t"
              key={i}
            >
              <div className="flex justify-between items-center">
                <h1 className="text-sm">{el.InvoiceId}</h1>
                <p className="text-xs">
                  <span className="font-semibold text-[#0aa1dd]">
                    Viwed at :{" "}
                  </span>
                  {moment(el.viewedAt).format("DD-MM-YYYY, hh:mm a")}
                </p>
              </div>
            </div>
          );
        })
      ) : (
        <div className="text-red-500 flex justify-center">
          <p className="text-center">Uploaded files not available!</p>
        </div>
      )}
    </div>
  );
};
export const UploadedFiles = ({ data }) => {
  if (!data || data.length === 0 || !data.url) {
    return <div>No uploaded file</div>;
  }
  let docType = data.url?.split(".").pop().toLowerCase();

  let Documents = data?.url;

  const extractFileName = (url) => {
    const parts = url.split("/");
    return parts[parts.length - 1];
  };

  const dataURItoBlob = (dataURI, type) => {
    const byteString = atob(dataURI.split(",")[1]);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    return new Blob([arrayBuffer], { type: type });
  };

  const handlePdf = (file) => {
    const fileName = extractFileName(file);
    const fileExtension = fileName.split(".").pop().toLowerCase();

    if (
      fileExtension === "jpeg" ||
      fileExtension === "jpg" ||
      fileExtension === "png"
    ) {
      // Handle image files with jsPDF
      const img = new Image();
      img.src = file;
      img.onload = () => {
        const pdf = new jsPDF();
        const marginLeft = 10; // Left margin
        const marginRight = 10; // Right margin
        const marginTop = 10; // Top margin
        const marginBottom = 10; // Bottom margin

        // Calculate available width and height after applying margins
        const availableWidth =
          pdf.internal.pageSize.getWidth() - (marginLeft + marginRight);
        const availableHeight =
          pdf.internal.pageSize.getHeight() - (marginTop + marginBottom);

        // Calculate image dimensions to fit within available space
        const imgWidth = Math.min(
          availableWidth,
          availableHeight * (img.naturalWidth / img.naturalHeight)
        );
        const imgHeight = imgWidth * (img.naturalHeight / img.naturalWidth);

        // Calculate positioning to center image within available space
        const xPos = (pdf.internal.pageSize.getWidth() - imgWidth) / 2;
        const yPos = (pdf.internal.pageSize.getHeight() - imgHeight) / 2;

        // Add image with margins
        pdf.addImage(img, "JPEG", xPos, yPos, imgWidth, imgHeight);
        const pdfDataUri = pdf.output("datauristring");
        const blob = dataURItoBlob(pdfDataUri, "application/pdf");
        const blobUrl = URL.createObjectURL(blob);
        const newTab = window.open(blobUrl);
        newTab.onload = () => {
          newTab.document.title = fileName;
        };
      };
    } else if (fileExtension === "docx") {
      // Open DOCX file in new tab
      const newTab = window.open(file, "_blank");
      if (newTab) {
        newTab.opener = null; // Ensure the new tab doesn't retain reference to the opener
        newTab.focus();
      } else {
        console.error("Failed to open new tab.");
      }
    } else if (fileExtension === "pdf") {
      // Handle PDF files
      fetch(file)
        .then((response) => response.arrayBuffer())
        .then(async (arrayBuffer) => {
          const pdfDoc = await PDFDocument.load(arrayBuffer);
          const pdfBytes = await pdfDoc.save();
          const blob = new Blob([pdfBytes], { type: "application/pdf" });
          const blobUrl = URL.createObjectURL(blob);
          const newTab = window.open(blobUrl);
          newTab.onload = () => {
            newTab.document.title = fileName;
          };
        })
        .catch((error) => console.error("Error loading PDF:", error));
    } else {
      console.error("Unsupported file type:", fileExtension);
    }
  };

  return (
    <div className="animatedView mt-6">
      <div className="relative">
        {docType === "jpg" || docType === "png" || docType === "jpeg" ? (
          <Image
            src={Documents}
            style={{
              width: 150,
              height: 150,
              borderRadius: 10,
              objectFit: "cover",
            }}
          />
        ) : docType === "pdf" ? (
          <div className="bg-white shadow-2xl border-t w-[150px] h-[150px] rounded-[10px] flex flex-col justify-between py-2.5">
            <div className="flex flex-col gap-3">
              <div className="flex justify-center">
                <FaRegFilePdf className="text-5xl text-red-600" />
              </div>
              <h1 className="text-sm text-gray-500 text-center">
                {Documents?.length > 13
                  ? Documents?.slice(0, 13) + "..."
                  : Documents}
              </h1>
            </div>
            <div className="flex justify-center">
              <button
                className="bg-red-600 py-2 px-5 text-white text-xs rounded"
                onClick={() => handlePdf(Documents)}
              >
                View PDF
              </button>
            </div>
          </div>
        ) : docType === "doc" || docType === "docx" ? (
          <div className="bg-white w-[150px] h-[150px] rounded-[10px] flex flex-col justify-between py-2.5">
            <div className="flex flex-col gap-3">
              <div className="flex justify-center">
                <FaRegFileWord className="text-5xl text-blue-800" />
              </div>
              <h1 className="text-sm text-gray-500 text-center">
                {Documents?.length > 13
                  ? Documents?.split("/").pop()?.slice(0, 13) + "..."
                  : Documents}
              </h1>
            </div>
            <div className="flex justify-center">
              <button
                className="bg-blue-800 py-2 px-5 text-white text-xs rounded"
                onClick={() => handlePdf(Documents)}
              >
                View Word
              </button>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};
export const SMSDetails = ({ Data, data }) => {
  return (
    <div className="animatedView p-2">
      <div className="flex flex-col gap-5">
        {Data?.length > 0 ? (
          Data?.map((el, i) => {
            return (
              <div
                key={i}
                className="bg-white shadow-xl border-t p-4 rounded-2xl"
              >
                <div>
                  <h1 className="text-base font-semibold">
                    {data?.sentVia} {i + 1}
                  </h1>
                </div>
                <hr className="my-2" />
                <div className=" my-3">
                  <div className="flex justify-between items-center">
                    <h1 className="text-sm font-semibold">
                      Subject :{" "}
                      <span className="text-sm">
                        Invoice for amount {data.amount} {data.currency} from{" "}
                        {data?.Client?.name}
                      </span>
                    </h1>
                    <h1 className="text-sm font-semibold">
                      At :{" "}
                      <span className="text-sm text-gray-600 font-normal">
                        {moment(el?.createdAt).format("DD-MM-YYYY, hh:mm a")}
                      </span>
                    </h1>
                  </div>
                  <h1 className="text-xs font-semibold mt-3">
                    Hi! {data?.Customer?.name}
                  </h1>
                  <p>
                    Here is your invoice{" "}
                    <Link
                      to={data.paymentLink}
                      className="text-blue-500 underline"
                      target="_blank"
                    >
                      {data.paymentLink}
                    </Link>{" "}
                    from {data?.Client?.name}
                  </p>
                </div>
              </div>
            );
          })
        ) : (
          <>No {data?.sentVia} Found!</>
        )}
      </div>
    </div>
  );
};
