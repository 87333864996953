import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { Logout } from "../../../../store/actions/AuthActions";
import { useDispatch } from "react-redux";

const CreateCustomer = () => {
  let accessToken = JSON.parse(localStorage.getItem("userDetails"));
  let Navigate = useNavigate();
  //   API
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");

  //   Error handlers
  const [Error, setError] = useState(false);

  //   Loaders
  const [Loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  function onLogout() {
    dispatch(Logout(Navigate));
    // window.location.reload();
  }

  const handleCreateCustomer = () => {
    if (name === "") {
      setError("name is required");
    } else if (mobile === "") {
      setError("mobile is required");
    } else if (mobile.length > 11 || mobile.length < 8) {
      setError("mobile number must be 8 to 11 digits only");
    } else if (email === "") {
      setError("email is required");
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      setError("invalid email address");
    } else {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

      var raw = JSON.stringify({
        name: name,
        email: email,
        mobileNumber: mobile,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + "customers/new",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result.message === "Successfully Created") {
            swal(
              "Created!",
              `${name.replace("-", " ")} account has been Created!`,
              "success"
            ).then(() => {
              setLoading(false);
              Navigate("/customers");
            });
          } else if (result.message === "unauthorized" || result.message === "token expire") {
            swal({
              title: "Session Expired!",
              text: `your session has expired! Kindly login again!`,
              icon: "warning",
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                onLogout();
              } else {
                onLogout();
              }
            });
          } else {
            swal("Denied!", `${result.message}`, "error");
            setLoading(false);
          }
        })
        .catch((error) => console.log("error", error));
    }
  };

  return (
    <div className="flex justify-start md:justify-center animatedView">
      <div className="w-full md:w-1/2">
        <div className="bg-white p-3 rounded-[20px]">
          <div className="">
            <h1 className="text-[#fff] bg-[#0aa1dd] rounded-md p-3 m-2 ml-5 z-[999] text-base">
              Add Customer
            </h1>
          </div>
          <div className="flex items-center relative">
            <div className="mx-5 w-full flex flex-col gap-3 animatedView">
              <div className="relative mt-4">
                <label htmlFor="document" className="font-semibold">
                  Name: <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  className={` border rounded-lg p-2 w-full`}
                  placeholder="Enter customer's name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="relative mt-2">
                <label htmlFor="document" className="font-semibold">
                  Mobile Number: <span className="text-red-500">*</span>
                </label>
                <input
                  type="number"
                  className={` border rounded-lg p-2 w-full`}
                  placeholder="Enter customer's mobile number..."
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
              </div>
              <div className="relative mt-2">
                <label htmlFor="document" className="font-semibold">
                  Email: <span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  className={`border rounded-lg p-2 w-full`}
                  placeholder="Enter customer's email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              {Error && (
                <div className="text-xs m-1 text-red-500 animatedView">
                  {Error}
                </div>
              )}
              {/* Button */}
              <div className=" flex justify-end my-3">
                <button
                  className="bg-gray-400 p-2.5 rounded-md text-white px-3 mx-3 flex items-center gap-3"
                  onClick={() => Navigate(-1)}
                >
                  Cancel
                </button>
                <button
                  className="p-2.5 bg-[#0aa1dd] rounded-md text-white px-3 flex items-center gap-3"
                  onClick={handleCreateCustomer}
                >
                  {Loading ? (
                    <Spinner animation="border" variant="light"></Spinner>
                  ) : (
                    <>
                      Add Customer
                      <span>
                        <i className="bi bi-check2-all"></i>
                      </span>
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCustomer;
