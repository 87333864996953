import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
// import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-daterangepicker/daterangepicker.css";
import moment from "moment";
import Number from "../../../jsxClient/components/NumberCounter/Number";
import homeBG from "../../../images/homeBG.png";
import { Empty } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Logout } from "../../../store/actions/AuthActions";

const Home = () => {
  let accessToken = JSON.parse(localStorage.getItem("userDetails"));

  let ExClient = [];

  const handleCopyClick = (email) => {
    const textarea = document.createElement("textarea");
    textarea.value = email;

    document.body.appendChild(textarea);

    textarea.select();

    document.execCommand("copy");

    document.body.removeChild(textarea);
    toast.success("Copied to clipboard!", {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  //Time Bar
  const [StartDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
  const [EndDate, setEndDate] = useState(
    moment().add(1, "days").format("YYYY-MM-DD")
  );
  const [dateRangeText, setDateRangeText] = useState(
    `${moment().format("DD/MM/YYYY")} - ${moment()
      .add(1, "days")
      .format("DD/MM/YYYY")}`
  );
  const [time, setTime] = useState("Today");
  const sortByTime = [
    { name: "Today" },
    { name: "Weekly" },
    { name: "Monthly" },
  ];

  const initialStartDate = moment().startOf("day");
  const initialEndDate = moment().endOf("day");

  const handleDateRangeChange = (event, picker) => {
    if (picker.startDate.format("YYYY-MM-DD") > moment().format("YYYY-MM-DD")) {
      toast.error("You Cannot see the future!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      setStartDate(picker.startDate.format("YYYY-MM-DD"));
      setEndDate(picker.endDate.format("YYYY-MM-DD"));
      //setIsTodayDisabled(picker.startDate.format("YYYY-MM-DD")<=moment().format("YYYY-MM-DD")  && picker.endDate.format("YYYY-MM-DD")>moment().format("YYYY-MM-DD"));
      setDateRangeText(
        `${picker.startDate.format("DD/MM/YYYY")} - ${picker.endDate.format(
          "DD/MM/YYYY"
        )}`
      );
    }
  };

  const handleChangeDate = (el) => {
    // setTime(el.name);
    // if (el.name === "Today") {
    //   setStartDate(moment().format("YYYY-MM-DD"));
    //   setEndDate(moment().add(1, "days").format("YYYY-MM-DD"));
    // } else if (el.name === "Weekly") {
    //   setStartDate(moment().startOf("week").format("YYYY-MM-DD"));
    //   setEndDate(moment().endOf("week").format("YYYY-MM-DD"));
    // } else if (el.name === "Monthly") {
    //   setStartDate(moment().startOf("month").format("YYYY-MM-DD"));
    //   setEndDate(moment().endOf("month").format("YYYY-MM-DD"));
    // }
    const ranges = {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
      "Last 7 Days": [moment().subtract(6, "days"), moment()],
      "Last 30 Days": [moment().subtract(29, "days"), moment()],
      "This Month": [moment().startOf("month"), moment().endOf("month")],
      "Last Month": [
        moment().subtract(1, "month").startOf("month"),
        moment().subtract(1, "month").endOf("month"),
      ],
    };
    const [start, end] = ranges[el.name];
    setStartDate(start);
    setEndDate(end);
    setDateRangeText(
      `${start.format("MMMM D, YYYY")} - ${end.format("MMMM D, YYYY")}`
    );
  };
  const predefinedRanges = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
    "Last 7 Days": [moment().subtract(6, "days"), moment()],
    "Last 30 Days": [moment().subtract(29, "days"), moment()],
    "This Month": [moment().startOf("month"), moment().endOf("month")],
    "Last Month": [
      moment().subtract(1, "month").startOf("month"),
      moment().subtract(1, "month").endOf("month"),
    ],
  };

  const [Data, setData] = useState();
  const [value, setValue] = useState(0);

  let Navigate = useNavigate();

  const dispatch = useDispatch();
  function onLogout() {
    dispatch(Logout(Navigate));
    // window.location.reload();
  }

  useEffect(() => {
    const getDashboardData = async () => {
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      await fetch(
        process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
          `dashboard?startDate=${StartDate}&endDate=${EndDate}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          if (result.message === "Unauthorized!" || result.message === "token expire") {
            onLogout();
          } else {
            setData(result);
          }
          //setValue(value+result?.valueOfPaidInvoices);
        })
        .catch((error) => console.log("error", error));
    };

    getDashboardData();
  }, [accessToken.accessToken, StartDate, EndDate]);

  return (
    <div
      style={{
        transform: "scale(.87)",
        transformOrigin: "0 0",
        width: "90vw",
        height: "",
      }}
    >
      <div className="row">
        <div className="col-xl-12">
          <div className="row">
            {/* calendar */}
            <div className="flex gap-[17px] justify-end">
              {/* <div className="p-2 rounded-[4px] bg-white mb-3 flex gap-2">
                {sortByTime.map((el, i) => {
                  return (
                    <button
                      className={`${
                        time === el.name
                          ? "bg-[#0aa1dd] p-1 rounded-[4px] px-2 text-xs text-white font-semibold"
                          : "text-[#0aa1dd] font-[500] text-xs px-2"
                      } `}
                      key={i}
                      onClick={() => handleChangeDate(el)}
                    >
                      {el.name}
                    </button>
                  );
                })}
              </div> */}
              <div className="p-2 rounded-[6px] bg-white mb-3">
                <DateRangePicker
                  initialSettings={{
                    startDate: initialStartDate.format("MM/DD/YYYY"),
                    endDate: initialEndDate.format("MM/DD/YYYY"),
                    ranges: predefinedRanges,
                  }}
                  onApply={handleDateRangeChange}
                >
                  <button className="text-[#0aa1dd] font-[500] text-base px-2 p-1 flex items-center gap-2">
                    {dateRangeText}
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        fontWeight={"200"}
                      >
                        <path
                          d="M13.6 1.6H12V0.8C12 0.587827 11.9157 0.384344 11.7657 0.234315C11.6157 0.0842854 11.4122 0 11.2 0C10.9878 0 10.7843 0.0842854 10.6343 0.234315C10.4843 0.384344 10.4 0.587827 10.4 0.8V1.6H5.6V0.8C5.6 0.587827 5.51571 0.384344 5.36569 0.234315C5.21566 0.0842854 5.01217 0 4.8 0C4.58783 0 4.38434 0.0842854 4.23431 0.234315C4.08429 0.384344 4 0.587827 4 0.8V1.6H2.4C1.76348 1.6 1.15303 1.85286 0.702944 2.30294C0.252856 2.75303 0 3.36348 0 4V13.6C0 14.2365 0.252856 14.847 0.702944 15.2971C1.15303 15.7471 1.76348 16 2.4 16H13.6C14.2365 16 14.847 15.7471 15.2971 15.2971C15.7471 14.847 16 14.2365 16 13.6V4C16 3.36348 15.7471 2.75303 15.2971 2.30294C14.847 1.85286 14.2365 1.6 13.6 1.6ZM14.4 13.6C14.4 13.8122 14.3157 14.0157 14.1657 14.1657C14.0157 14.3157 13.8122 14.4 13.6 14.4H2.4C2.18783 14.4 1.98434 14.3157 1.83431 14.1657C1.68429 14.0157 1.6 13.8122 1.6 13.6V8H14.4V13.6ZM14.4 6.4H1.6V4C1.6 3.78783 1.68429 3.58434 1.83431 3.43431C1.98434 3.28429 2.18783 3.2 2.4 3.2H4V4C4 4.21217 4.08429 4.41566 4.23431 4.56569C4.38434 4.71571 4.58783 4.8 4.8 4.8C5.01217 4.8 5.21566 4.71571 5.36569 4.56569C5.51571 4.41566 5.6 4.21217 5.6 4V3.2H10.4V4C10.4 4.21217 10.4843 4.41566 10.6343 4.56569C10.7843 4.71571 10.9878 4.8 11.2 4.8C11.4122 4.8 11.6157 4.71571 11.7657 4.56569C11.9157 4.41566 12 4.21217 12 4V3.2H13.6C13.8122 3.2 14.0157 3.28429 14.1657 3.43431C14.3157 3.58434 14.4 3.78783 14.4 4V6.4Z"
                          fill="#0AA1DD"
                        />
                      </svg>
                    </span>
                  </button>
                </DateRangePicker>
              </div>
            </div>
            {/* Main Crds */}
            <div className="col-xl-6">
              <div className="row">
                <div className="col-xl-12">
                  <div
                    className="card h-auto"
                    style={{
                      backgroundImage: `url(${homeBG})`,
                      backgroundRepeat: "no-repeat",
                      width: "100%",
                      backgroundSize: "cover",
                    }}
                  >
                    <div
                      className="card-body row py-20"
                      style={{ margin: "32px 0px" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 25,
                          marginTop: 35,
                        }}
                      >
                        <div>
                          <h4 style={{ color: "#fff" }}>
                            Number of Paid Invoices
                          </h4>
                          <Number
                            style={{
                              color: "#fff",
                              fontWeight: 700,
                              fontSize: "55px",
                            }}
                            endValue={Data ? Data.invoicesPaid : 0}
                          />
                        </div>
                        <div>
                          <h4 style={{ color: "#fff" }}>
                            Value of Paid Invoices
                          </h4>
                          <p
                            style={{
                              color: "#fff",
                              fontWeight: 700,
                              fontSize: "55px",
                            }}
                          >
                            {Data ? Data?.valueOfPaidInvoices : 0}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Grid Cards */}
            <div className="col-xl-6">
              <div className="row">
                <div className="col-xl-12">
                  <div className="row">
                    <div className="col-xl-6 col-sm-6">
                      <div
                        className="card"
                        style={{
                          boxShadow: "13px 13px 13px 0px rgba(0,0,0,0.1)",
                        }}
                      >
                        <div className="card-body d-flex p-5 justify-content-between">
                          <div>
                            <h4 className="fs-18 font-w500 text-[#545454] mb-4">
                              Invoice Created
                            </h4>
                            <div className="d-flex align-items-center py-1">
                              <Number
                                className="font-w700 mb-0 text-[55px]"
                                endValue={Data ? Data?.invoicesCreated : 0}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-6 col-sm-6">
                      <div
                        className="card cardShadow"
                        style={{
                          boxShadow: "13px 13px 13px 0px rgba(0,0,0,0.1)",
                        }}
                      >
                        <div className="card-body d-flex p-5 justify-content-between">
                          <div>
                            <h4 className="fs-18 font-w500 text-[#545454] mb-4">
                              Number of Unpaid Invoices
                            </h4>
                            <div className="d-flex align-items-center">
                              <Number
                                className="font-w700 mb-0 text-[55px]"
                                endValue={Data ? Data.unpaidInvoices : 0}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-6 col-sm-6">
                      <div
                        className="card"
                        style={{
                          boxShadow: "13px 13px 13px 0px rgba(0,0,0,0.1)",
                        }}
                      >
                        <div className="card-body d-flex p-5 justify-content-between">
                          <div>
                            <h4 className="fs-18 font-w500 text-[#545454] mb-4">
                              Value of Transactions
                            </h4>
                            <div className="d-flex align-items-center">
                              <p className="font-w700 mb-0 text-[55px]">
                                {Data
                                  ? parseFloat(Data?.valuesOfTransactions)
                                  : 0}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-sm-6">
                      <div
                        className="card"
                        style={{
                          boxShadow: "13px 13px 13px 0px rgba(0,0,0,0.1)",
                        }}
                      >
                        <div className="card-body d-flex p-5 justify-content-between">
                          <div>
                            <h4 className="fs-18 font-w500 text-[#545454] mb-4">
                              Expiring Invoices
                            </h4>
                            <div className="d-flex align-items-center">
                              <Number
                                className=" font-w700 mb-0 text-[55px]"
                                endValue={
                                  Data ? Data.expiringInvoice.length : 0
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-3 -mt-1">
              {/* Table */}
              <div className="flex flex-col md:flex-col lg:flex-row gap-[18px] md:gap-[28px] mb-5 mt-2.5">
                <div className="bg-[#fff] p-5 rounded-xl shadow-2xl w-full md:w-full lg:w-1/2">
                  <h1 className="font-semibold text-[#0aa1dd] py-2 text-lg">
                    Today’s Invoice
                  </h1>
                  <div className="relative overflow-x-scroll">
                    <table className="w-full text-sm text-left rtl:text-right text-gray-500 overflow-x-scroll">
                      <thead className="text-xs text-gray-700 uppercase bg-[#eee]">
                        <tr>
                          <th scope="col" className="px-4 py-3">
                            Payment Ref
                          </th>
                          <th scope="col" className="px-4 py-3">
                            Invoice Value
                          </th>
                          <th scope="col" className="px-4 py-3 w-6">
                            Invoice Status
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Data && Data?.todaysInvoice?.length > 0 ? (
                          Data?.todaysInvoice?.slice(0, 5)?.map((el, i) => {
                            return (
                              <tr className="bg-white border-b" key={i}>
                                <td className="px-3 py-2 text-[#545454] text-nowrap font-semibold">
                                  {el.paymentRef}
                                </td>
                                <td className="px-3 py-2 text-[#545454] text-nowrap">
                                  {el.amount}
                                </td>
                                <td
                                  className="px-3 py-2 text-[#545454] cursor-pointer"
                                  onClick={() => handleCopyClick(el.email)}
                                >
                                  {el.status === "pending" ? (
                                    <span className="text-orange-500 font-semibold capitalize">
                                      {el.status}
                                    </span>
                                  ) : (
                                    <span className="text-green-500 font-semibold capitalize">
                                      {el.status}
                                    </span>
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <tr className="p-4">
                            <td className="text-red-500 font-semibold"></td>
                            <td className="text-red-500 font-semibold flex justify-start ">
                              <Empty className="my-2" />
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    {Data?.todaysInvoice?.length > 0 && (
                      <Link
                        to="/invoice-list"
                        className="flex justify-end underline my-2"
                      >
                        see all
                      </Link>
                    )}
                  </div>
                </div>
                <div className="bg-[#fff] p-5 rounded-xl shadow-2xl w-full md:w-full lg:w-1/2">
                  <h1 className="font-semibold text-[#0aa1dd] text-primary text-lg py-2">
                    Today’s Paid Invoice
                  </h1>
                  <div className="relative overflow-x-scroll">
                    <table className="w-full text-sm text-left rtl:text-right overflow-x-scroll">
                      <thead className="text-xs text-gray-700 uppercase bg-[#eee]">
                        <tr>
                          <th scope="col" className="px-3 py-[12.5px]">
                            Payment Ref
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-[12.5px] whitespace-nowrap"
                          >
                            Invoice Value
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-[12.5px] whitespace-nowrap"
                          >
                            Commission
                          </th>
                          <th scope="col" className="px-3 py-[12.5px]">
                            Transaction ID
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Data?.todaysPaidInvoices?.slice(0, 5)?.map((el, i) => {
                          return (
                            <tr className="bg-white border-b" key={i}>
                              <th
                                scope="row"
                                className="px-3 py-[12.5px] text-gray-900 whitespace-nowrap font-semibold"
                              >
                                {el.paymentRef}
                              </th>
                              <td className="px-3 py-[12.5px]">{el.amount}</td>
                              <td className="px-3 py-[12.5px]">{"-"}</td>
                              <td className="px-3 py-[12.5px]">
                                {el.transactionId}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    {Data?.todaysPaidInvoices?.length > 0 && (
                      <Link
                        to="/invoice-list"
                        className="flex justify-end underline my-2"
                      >
                        see all
                      </Link>
                    )}
                    {Data?.todaysPaidInvoices?.length === 0 && (
                      <div
                        className="flex justify-center"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          marginTop: 30,
                        }}
                      >
                        <Empty />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};
export default Home;
