import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Input } from "../Components";
import headerImage from "../Images/rect-header.png";
import "./index.css";
import { useDispatch } from "react-redux";
import { Spinner } from "react-bootstrap";
import swal from "sweetalert";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const EmailInputRef = useRef(null);
  const passwordInputRef = useRef(null);

  const [Email, setEmail] = useState("");
  const [Password, setPassword] = useState("");

  // Password Visibility Hook
  const [passType, setPassType] = useState("password");

  const hidePassword = (e) => {
    e.preventDefault();
    setPassType("password");
  };
  const showPassword = (e) => {
    e.preventDefault();
    setPassType("text");
  };

  // Error Hooks
  const [EmailError, setEmailError] = useState("");
  const [PasswordError, setPasswordError] = useState("");

  // Loading Hooks
  const [loading, setLoading] = useState(false);
  const [success, setsuccess] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setEmailError("");
    setPasswordError("");
    if (Email === "") {
      setEmailError("This Field is required");
      setLoading(false);
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(Email)) {
      setEmailError("please enter a valid email");
      setLoading(false);
    } else {
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        email: Email,
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
          "auth/forgot-password/",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result.message === "Instructions sent to email") {
            swal(
              "Email Sent!",
              "Check your email to reset password",
              "success"
            );
            setsuccess(true);
            setLoading(false);
            Navigate("/login");
          } else {
            swal(result?.message);
            setLoading(false);
          }
        })
        .catch((error) => console.error(error));
    }
  };

  return (
    <div className="background-1-login" style={{ height: "100vh" }}>
      <header
        className="wrapper-login header-login"
        style={{ paddingBottom: "100px" }}
      >
        <span className="text-lg text-bold text-light-login">LOG IN</span>
        <img src={headerImage} alt="" />
      </header>
      <form className="wrapper-login card-login animatedView">
        <div className="form-group-login">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <label htmlFor="name" className="text-sm text-bold text-dark-login">
              Email
            </label>
            {EmailError && (
              <div
                className="animatedView"
                style={{ color: "red", fontSize: 10, transition: "all" }}
              >
                {EmailError}
              </div>
            )}
          </div>
          <Input
            ref={EmailInputRef}
            type="text"
            placeholder="Enter your email"
            id="name"
            value={Email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <p style={{ fontWeight: 500, marginLeft: 10, color: "#a5a5a5" }}>
            Enter your email address associated with the account.
          </p>
        </div>
        {/* <div className="form-group-login">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <label
              htmlFor="password"
              className="text-sm text-bold text-dark-login"
            >
              Password
            </label>
            {PasswordError && (
              <div
                className="animatedView"
                style={{ color: "red", fontSize: 10, transition: "all" }}
              >
                {PasswordError}
              </div>
            )}
          </div>
          <div style={{ position: "relative" }}>
            <Input
              ref={passwordInputRef}
              type={passType}
              placeholder="Enter your password"
              id="password"
              value={Password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div style={{ position: "absolute", top: 15, right: 15 }}>
              {passType === "password" ? (
                <div className="cursor-pointer" onClick={showPassword}>
                  <i
                    className="bi bi-eye-slash-fill h2"
                    style={{ fontSize: "2rem", color: "#A1A1A1" }}
                  ></i>
                </div>
              ) : (
                <div className="cursor-pointer" onClick={hidePassword}>
                  <i
                    className="bi bi-eye-fill h2"
                    style={{ fontSize: "2rem", color: "#A1A1A1" }}
                  ></i>
                </div>
              )}
            </div>
          </div>
        </div> */}

        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "40px",
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            disabled={loading || success}
          >
            {loading ? (
              <Spinner animation="border" variant="light" />
            ) : (
              <>{success ? "Email Sent" : "Next"}</>
            )}
          </Button>
        </div>
        <div style={{ textAlign: "right", paddingTop: "40px" }}>
          <Link to="/login" className="link-login text-sm text-dark-login">
            Already have account.{" "}
            <span style={{ fontWeight: "bold", color: "#0aa1dd" }}>Login?</span>
          </Link>
        </div>
      </form>

      {/* <p
        className="wrapper-login text-sm"
        style={{
          margin: "auto",
          textAlign: "center",
          paddingTop: "40px",
          paddingBottom: "40px",
        }}
      >
        Don't you have an account?{" "}
        <Link to="/page-register" className="text-primary text-bold">
          REGISTER
        </Link>
      </p> */}
    </div>
  );
};

export default ForgotPassword;
