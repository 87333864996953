import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from "react";
import { useDispatch } from "react-redux";
import { Logout } from "../store/actions/AuthActions";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

const NotificationContext = createContext();

export const NotificationContextProvider = ({ children }) => {
  const [Notification, setNotification] = useState([]);
  const [IsNewNotification, setIsNewNotification] = useState(false);
  const previousNotificationCount = useRef(0); // Ref to store the previous notification count

  let accessToken = JSON.parse(localStorage.getItem("userDetails"));
  let navigate = useNavigate();
  const dispatch = useDispatch();
  function onLogout() {
    dispatch(Logout(navigate));
    // window.location.reload();
  }

  const getNotifications = () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + "notifications/",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.notifications) {
          const newNotificationCount = result.notifications.length;

          // Compare the new count with the previous count stored in the ref
          if (newNotificationCount > previousNotificationCount.current) {
            setIsNewNotification(true);
          } else {
            setIsNewNotification(false);
          }

          // Update the previous count and the state
          previousNotificationCount.current = newNotificationCount;
          setNotification(result.notifications);
        } else if (result.message === "unauthorized" || result.message === "token expire") {
          swal({
            title: "Session Expired!",
            text: `your session has expired! Kindly login again!`,
            icon: "warning",
            dangerMode: true,
          }).then((willDelete) => {
            if (willDelete) {
              onLogout();
            } else {
              onLogout();
            }
          });
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    getNotifications();

    const intervalId = setInterval(getNotifications, 15000); // 15000ms = 15 seconds

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, [accessToken.accessToken]);

  return (
    <NotificationContext.Provider
      value={{
        Notification,
        IsNewNotification,
        setIsNewNotification,
        refresh: () => getNotifications(),
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotifications = () => useContext(NotificationContext);
