import React, { Fragment, useContext, useState } from "react";
/// React router dom
import { Link } from "react-router-dom";
import { ThemeContext } from "../../../context/ThemeContext";
import logo from "../../../jsxClient/pages/Auth/Images/rect-header.png";

export function NavMenuToggle() {
  setTimeout(() => {
    let mainwrapper = document.querySelector("#main-wrapper");
    if (mainwrapper.classList.contains("menu-toggle")) {
      mainwrapper.classList.remove("menu-toggle");
    } else {
      mainwrapper.classList.add("menu-toggle");
    }
  }, 200);
}

const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const { navigationHader, background } = useContext(ThemeContext);
  return (
    <div className="nav-header">
      <Link to="/dashboard" className="brand-logo">
        {background.value === "dark" || navigationHader !== "color_1" ? (
          <Fragment>
            <img src={logo} alt="" style={{ width: 100 }} />
            <div className="brand-title">
              <h2 className="" style={{ colo: "#0AA1DD" }}>
                Logo.
              </h2>
              {/* <span className="brand-sub-title">Saas Admin Dashboard</span> */}
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <img src={logo} alt="" style={{ width: 100 }} />
            <div className="brand-title">
              <h2 className="" style={{ colo: "#0AA1DD" }}>
                Logo.
              </h2>
              {/* <span className="brand-sub-title">Saas Admin Dashboard</span> */}
            </div>
          </Fragment>
        )}
      </Link>

      <div
        className="nav-control"
        onClick={() => {
          setToggle(!toggle);
          NavMenuToggle();
        }}
      >
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
