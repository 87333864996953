import React from "react";
import info from "../../../../../images/svg/info.svg";
import moment from "moment";
import { Link } from "react-router-dom";

const BasicInfo = ({ Card, Label, Desc, data }) => {
  return (
    <Card Head="Basic Info" Icons={info}>
      <div>
        <Label label="Payment Ref" />
        <Desc desc={data?.customerRef} />
      </div>
      <div>
        <Label label="Invoice Status" />
        <Desc desc={data?.status} />
      </div>
      <div>
        <Label label="Deposit Status" />
        <Desc desc="Not Deposited" />
      </div>
      <div>
        <Label label="Invoice Value" />
        <Desc desc={data?.amount + " " + data?.currency} />
      </div>
      <div>
        <Label label="Created Date" />
        <Desc desc={moment(data?.createdAt).format("DD-MM-YYYY, hh:mm a")} />
      </div>
      <div>
        <Label label="Created By" />
        <Desc desc={data?.Client?.name} />
      </div>
      <div>
        <Label label="Last Sent Date" />
        <Desc desc={moment(data?.updatedAt).format("DD-MM-YYYY, hh:mm a")} />
      </div>
      <div>
        <Label label="Expire Date" />
        <Desc desc={moment(data?.expiryDate).format("DD-MM-YYYY")} />
      </div>
      <div className="flex flex-col gap-1">
        <Label label="Invoice Url" />
        <Link
          to={data?.paymentLink}
          target="_blank"
          className="underline text-blue-500 hover:underline"
        >
          Click here to view invoice
        </Link>
      </div>
      <div>
        <Label label="Invoice Display Value" />
        <Desc desc={data?.amount + " " + data?.currency} />
      </div>
      {/* <div>
        <Label label="Invoice Language" />
        <Desc desc="-" />
      </div> */}
      <div>
        <Label label="Invoice Type" />
        <Desc desc={data?.sentVia} />
      </div>
    </Card>
  );
};

export default BasicInfo;
