import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import swal from "sweetalert";

const CreateCustomer = () => {
  let accessToken = JSON.parse(localStorage.getItem("userDetails"));

  //   API
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");

  //   Error handlers
  const [Error, setError] = useState(false);

  //   Loaders
  const [Loading, setLoading] = useState(false);

  const handleCreateCustomer = () => {
    if (name === "") {
      setError("name is required");
    } else if (email === "") {
      setError("email is required");
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setError("email is not valid");
    } else if (mobile === "") {
      setError("mobile is required");
    } else if (mobile.length > 11 || mobile.length < 8) {
      setError("mobile number must be between 8 to 11 digits only.");
    }  else {
      setLoading(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

      var raw = JSON.stringify({
        name: name,
        email: email,
        mobileNumber: mobile,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL + "customers/new",
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          console.log(result);
          if (result.message === "Successfully Created") {
            swal(
              "Approved!",
              `${name.replace("-", " ")} account has been Created!`,
              "success"
            ).then(() => {
              setLoading(false);
              setName("");
              setEmail("");
              setMobile("");
              setError("");
            });
          } else {
            swal("Denied!", `${result.message}`, "error");
            setLoading(false);
          }
        })
        .catch((error) => console.log("error", error));
    }
  };

  return (
    <div className="flex justify-start md:justify-center animatedView">
      <div className="w-full md:w-1/2">
        <div className="flex ml-14">
          <h1 className="text-[#fff] bg-[#0aa1dd] rounded-md p-3 m-2 ml-5 z-[999] text-xl">
            Add Customer
          </h1>
        </div>
        <div className="bg-white h-[43vh] p-3 rounded-[20px] flex items-center relative -mt-12">
          <div className="mx-5 w-full flex flex-col gap-3 animatedView">
            <div className="relative mt-5">
              <label htmlFor="document" className="font-semibold">
                Name:
              </label>
              <input
                type="text"
                className={`${
                  Error && Error.length > 0
                    ? "border border-red-500 placeholder:text-red-500"
                    : "border border-gray-200"
                } border rounded-lg p-2 w-full`}
                placeholder="enter customer's name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="relative mt-2">
              <label htmlFor="document" className="font-semibold">
                Email:
              </label>
              <input
                type="email"
                className={`${
                  Error && Error.length > 0
                    ? "border border-red-500 placeholder:text-red-500"
                    : "border border-gray-200"
                } border rounded-lg p-2 w-full`}
                placeholder="enter customer's email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="relative mt-2">
              <label htmlFor="document" className="font-semibold">
                Mobile Number:
              </label>
              <input
                type="number"
                className={`${
                  Error && Error.length > 0
                    ? "border border-red-500 placeholder:text-red-500"
                    : "border border-gray-200"
                } border rounded-lg p-2 w-full`}
                placeholder="enter customer's mobile number"
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </div>
            {Error && (
              <div className="text-xs m-1 text-red-500 animatedView">
                {Error}
              </div>
            )}
            {/* Button */}
            <div className=" flex justify-end mt-2">
              <button
                className="p-2.5 bg-[#0aa1dd] rounded-md text-white px-3 flex items-center gap-3"
                onClick={handleCreateCustomer}
              >
                {Loading ? (
                  <Spinner animation="border" variant="light"></Spinner>
                ) : (
                  <>
                    Add Customer
                    <span>
                      <i className="bi bi-check2-all"></i>
                    </span>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateCustomer;
