import { DatePicker } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import { Empty, ConfigProvider, Pagination } from "antd";
import { useDispatch } from "react-redux";
import { Logout } from "../../../../store/actions/AuthActions";
import swal from "sweetalert";

const Customers = () => {
  let accessToken = JSON.parse(localStorage.getItem("userDetails"));
  let Navigate = useNavigate();
  const [ExpireDate, setExpireDate] = useState("");
  const [StartDate, setStartDate] = useState("");
  const [EndDate, setEndDate] = useState("");
  // Expire Date
  const handleExpireChange = (date, dateString) => {
    setExpireDate(dateString);
  };
  // Start Date
  const handleStartChange = (date, dateString) => {
    setStartDate(dateString);
  };
  // End Date
  const handleEndChange = (date, dateString) => {
    setEndDate(dateString);
  };
  // API
  const [CustomerData, setCustomerData] = useState([]);
  const [SearchLoad, setSearchLoad] = useState(false);
  const [SearchValue, setSearchValue] = useState("");
  const [Page, setPage] = useState(1);
  const [Limit, setLimit] = useState(5);
  const [TotalCount, setTotalCount] = useState();

  const onShowSizeChange = (current, pageSize) => {
    setLimit(pageSize);
  };

  const dispatch = useDispatch();
  function onLogout() {
    dispatch(Logout(Navigate));
    // window.location.reload();
  }

  useEffect(() => {
    const getCustomerData = async () => {
      setSearchLoad(true);
      var myHeaders = new Headers();
      myHeaders.append("Authorization", `Bearer ${accessToken.accessToken}`);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      await fetch(
        process.env.REACT_APP_PAYMENT_GATEWAY_BASE_URL +
          `customers?startDate=${StartDate}&endDate=${EndDate}&expiryDate=${ExpireDate}&q=${SearchValue}&page=${Page}&limit=${Limit}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          // console.log(result);
          if (result.customers) {
            setSearchLoad(false);
            setCustomerData(result.customers);
            setTotalCount(result.totalRecords);
          } else if (result.message === "unauthorized" || result.message === "token expire") {
            swal({
              title: "Session Expired!",
              text: `your session has expired! Kindly login again!`,
              icon: "warning",
              dangerMode: true,
            }).then((willDelete) => {
              if (willDelete) {
                onLogout();
              } else {
                onLogout();
              }
            });
          }
        })
        .catch((error) => console.log("error", error));
      setSearchLoad(false);
    };
    getCustomerData();
  }, [accessToken.accessToken, StartDate, EndDate, SearchValue, Page, Limit]);

  const [ShowFilters, setShowFilters] = useState(false);

  return (
    <div className="flex flex-col gap-10 md:gap-14">
      {/* Filter */}
      <div className="flex justify-between items-center">
        <div>
          <button
            onClick={() => Navigate("/create-customer")}
            className="bg-[#0aa1dd] p-2 px-5 font-semibold text-white rounded"
          >
            Create Customer
          </button>
        </div>
        <button
          className="flex gap-2 items-center"
          onClick={() => setShowFilters(!ShowFilters)}
        >
          <span className="text-[#0AA1DD] text-sm">
            {ShowFilters ? "Clear" : "Advance Filter"}
          </span>
          <span className="border-[2px] p-1 border-[#0aa1dd] rounded-md">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="20"
              viewBox="0 0 22 20"
              fill="none"
            >
              <path
                d="M21.6425 0H0.330021C0.240051 0.00694095 0.156034 0.0476488 0.0948273 0.113956C0.033621 0.180262 -0.000246098 0.267263 2.12422e-05 0.3575V1.54C-0.000804896 1.6612 0.022474 1.78136 0.0685041 1.89348C0.114534 2.00561 0.182397 2.10746 0.268146 2.19312L8.51814 10.4431V17.3181L13.5025 19.8V10.4294L21.7525 2.17937C21.9103 2.00913 21.9986 1.78591 22 1.55375V0.3575C22 0.262685 21.9623 0.171754 21.8953 0.104709C21.8282 0.0376652 21.7373 0 21.6425 0Z"
                fill="#0AA1DD"
              />
            </svg>
          </span>
        </button>
      </div>
      {/* Grid Filters */}
      {ShowFilters && (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-9 animatedView">
          {/* Start */}
          <div className="w-full flex flex-col">
            <label htmlFor="user" className="text-[#555] text-sm font-semibold">
              Created Date From
            </label>
            <div className="relative">
              <DatePicker
                onChange={handleStartChange}
                className="w-full p-2 px-3 placeholder:text-black placeholder:font-semibold border-none"
                placeholder="dd/mm/yyyy"
              />
            </div>
          </div>
          {/* End */}
          <div className="w-full flex flex-col">
            <label htmlFor="user" className="text-[#555] text-sm font-semibold">
              Created Date To
            </label>
            <div className="relative">
              <DatePicker
                onChange={handleEndChange}
                className="w-full p-2 px-3 placeholder:text-black placeholder:font-semibold border-none"
                placeholder="dd/mm/yyyy"
              />
            </div>
          </div>
        </div>
      )}
      {/* Search */}
      <div className="flex justify-between">
        <div />
        <div className="relative">
          <input
            type="search"
            name="search"
            id=""
            className="p-2.5 w-[200px] md:w-[300px] bg-white rounded-lg"
            placeholder="search here..."
            onChange={(e) => setSearchValue(e.target.value)}
          />
          {SearchLoad && (
            <div className="absolute top-3 right-16">
              <Spinner animation="border" size="sm" color="#0aa1dd" />
            </div>
          )}
          <div className="absolute top-0 right-0 p-2.5 px-3 bg-[#0aa1dd] rounded-r-lg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="18"
              height="18"
            >
              <g
                data-name="Layer 2"
                fill="#ffffff"
                className="color000 svgShape"
              >
                <path
                  d="m20.71 19.29-3.4-3.39A7.92 7.92 0 0 0 19 11a8 8 0 1 0-8 8 7.92 7.92 0 0 0 4.9-1.69l3.39 3.4a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42zM5 11a6 6 0 1 1 6 6 6 6 0 0 1-6-6z"
                  data-name="search"
                  fill="#ffffff"
                  className="color000 svgShape"
                ></path>
              </g>
            </svg>
          </div>
        </div>
      </div>
      {/* Table */}
      <div className="relative overflow-x-auto">
        <table className="table text-gray-400 border-separate space-y-6 text-sm">
          <thead className="bg-blue-500 text-white">
            <tr>
              <th className="px-3 py-4">No.</th>
              <th className="px-3 py-4 text-left">Customer Name</th>
              <th className="px-3 py-4 text-left">ID-Customer</th>
              <th className="px-3 py-4 text-left">Contact Number</th>
              <th className="px-3 py-4 text-left">Email</th>
              <th className="px-3 py-4 text-left">Action</th>
            </tr>
          </thead>
          <tbody>
            {CustomerData?.length > 0 ? (
              CustomerData.map((el, i) => {
                return (
                  <tr
                    className="bg-transparent border-2 border-black lg:text-black text-base animatedView"
                    key={i}
                  >
                    <td className="p-3">{i + 1}</td>
                    <td className="p-3">{el.name}</td>
                    <td className="p-3 uppercase">{el.id}</td>
                    <td className="p-3 uppercase">{el.mobileNumber}</td>
                    <td className="p-3">{el.email}</td>
                    <td className="p-3 uppercase">
                      <button
                        onClick={() =>
                          Navigate(
                            `${el.id}/${el.name}/${el.email}/${el.mobileNumber}/update-customer`
                          )
                        }
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            d="M4.5 2.25C3.90326 2.25 3.33097 2.48705 2.90901 2.90901C2.48705 3.33097 2.25 3.90326 2.25 4.5V13.5C2.25 14.0967 2.48705 14.669 2.90901 15.091C3.33097 15.5129 3.90326 15.75 4.5 15.75H13.5C14.0967 15.75 14.669 15.5129 15.091 15.091C15.5129 14.669 15.75 14.0967 15.75 13.5V11.25C15.75 10.9516 15.8685 10.6655 16.0795 10.4545C16.2905 10.2435 16.5766 10.125 16.875 10.125C17.1734 10.125 17.4595 10.2435 17.6705 10.4545C17.8815 10.6655 18 10.9516 18 11.25V13.5C18 14.6935 17.5259 15.8381 16.682 16.682C15.8381 17.5259 14.6935 18 13.5 18H4.5C3.30653 18 2.16193 17.5259 1.31802 16.682C0.474106 15.8381 0 14.6935 0 13.5V4.5C0 3.30653 0.474106 2.16193 1.31802 1.31802C2.16193 0.474106 3.30653 0 4.5 0H6.75C7.04837 0 7.33452 0.118527 7.5455 0.329505C7.75647 0.540484 7.875 0.826631 7.875 1.125C7.875 1.42337 7.75647 1.70952 7.5455 1.92049C7.33452 2.13147 7.04837 2.25 6.75 2.25H4.5ZM11.25 2.25C10.9516 2.25 10.6655 2.13147 10.4545 1.92049C10.2435 1.70952 10.125 1.42337 10.125 1.125C10.125 0.826631 10.2435 0.540484 10.4545 0.329505C10.6655 0.118527 10.9516 0 11.25 0H16.875C17.1734 0 17.4595 0.118527 17.6705 0.329505C17.8815 0.540484 18 0.826631 18 1.125V6.75C18 7.04837 17.8815 7.33452 17.6705 7.5455C17.4595 7.75647 17.1734 7.875 16.875 7.875C16.5766 7.875 16.2905 7.75647 16.0795 7.5455C15.8685 7.33452 15.75 7.04837 15.75 6.75V3.84075L12.0465 7.5465C11.9419 7.6511 11.8177 7.73407 11.6811 7.79068C11.5444 7.84729 11.3979 7.87642 11.25 7.87642C11.1021 7.87642 10.9556 7.84729 10.8189 7.79068C10.6823 7.73407 10.5581 7.6511 10.4535 7.5465C10.3489 7.4419 10.2659 7.31773 10.2093 7.18106C10.1527 7.0444 10.1236 6.89792 10.1236 6.75C10.1236 6.60208 10.1527 6.4556 10.2093 6.31894C10.2659 6.18227 10.3489 6.0581 10.4535 5.9535L14.1593 2.25H11.25Z"
                            fill="#A1A1A1"
                          />
                        </svg>
                      </button>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td></td>
                <td></td>
                <td>
                  <Empty />
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="flex justify-end py-3 border-t">
          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#0aa1dd",
              },
            }}
          >
            <Pagination
              defaultCurrent={Page}
              total={TotalCount}
              pageSize={Limit}
              onChange={(val) => setPage(val)}
              showSizeChanger
              onShowSizeChange={onShowSizeChange}
            />
          </ConfigProvider>
        </div>
      </div>
    </div>
  );
};

export default Customers;
