import React from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useNotifications } from "../../../context/Notification";

const Notifications = () => {
  let Navigate = useNavigate();
  let { Notification, refresh } = useNotifications();

  return (
    <div className="animatedView mb-20">
      <div className="flex justify-center">
        <div className="flex flex-col gap-3 w-full lg:w-2/3">
          {Notification?.length > 0 ? (
            Notification?.map((el, i) => {
              return (
                <div
                  className="bg-white rounded-2xl p-4 flex justify-between items-center"
                  key={i}
                >
                  <div className="flex flex-col gap-3">
                    <div className="flex items-center gap-3">
                      <div className="border border-[#0aa1dd] p-2 rounded">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={20}
                          height={20}
                          viewBox="0 0 20 20"
                          id="message"
                        >
                          <g
                            fill="none"
                            fillRule="evenodd"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <g
                              stroke="#0aa1dd"
                              strokeWidth={2}
                              transform="translate(-1745 -1753)"
                            >
                              <g transform="translate(1746 1754)">
                                <path d="M18 12a2 2 0 0 1-2 2H4l-4 4V2a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v10z" />
                              </g>
                            </g>
                          </g>
                        </svg>
                      </div>
                      <h3>
                        {el.type === "invoice_paid" ? "Payment Received" : "-"}
                      </h3>
                    </div>
                    <div>
                      {el.type === "invoice_paid" ? (
                        <p className="text-gray-500 text-sm">
                          {el.Invoice?.Customer?.name} paid{" "}
                          <span className="text-green-600 font-semibold">
                            {el.Invoice?.amount} KWD
                            
                          </span>
                          <span> at {moment(el?.createdAt).format(
                            "DD-MM-YYYY, hh:mm a"
                          )}</span>
                         
                        </p>
                      ) : (
                        <p className="text-gray-500 text-sm">
                          {/* {el.Requirement?.Registration?.User?.fullname} has
                          submitted the requirement form at{" "}
                          {moment(el.Requirement?.createdAt).format(
                            "DD-MM-YYYY, hh:mm a"
                          )} */}
                          -
                        </p>
                      )}
                    </div>
                  </div>
                  <div>
                    <button
                      className="p-3 border border-[#0aa1dd] rounded-2xl text-base text-[#0aa1dd] hover:bg-[#0aa1dd] hover:text-white transition-all duration-300"
                      onClick={
                        el.type === "invoice_paid"
                          ? () =>
                              Navigate(
                                `/${el.Invoice?.id}/invoice-detail`
                              )
                          : "-"
                      }
                    >
                      See
                    </button>
                  </div>
                </div>
              );
            })
          ) : (
            <div className="flex justify-center h-[50vh] w-screen items-center">
              <p className="font-semibold text-base">No Notification!</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Notifications;
